"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _react = require("react");

// import
// definition
var _default = function _default(color, socialMediaType) {
  return (0, _react.useMemo)(function () {
    return {
      NONE: {
        fill: color
      },
      CIRCLE: {
        stroke: color,
        fill: color
      },
      CIRCLE_FILLED: {
        fill: color
      },
      ORIGIN: {}
    }[socialMediaType];
  }, [color, socialMediaType]);
};

exports["default"] = _default;