"use strict";

require("core-js/modules/es.array.concat.js");

require("core-js/modules/es.array.filter.js");

require("core-js/modules/es.object.to-string.js");

require("core-js/modules/es.promise.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _react = require("react");

var _link = require("@meepshop/link");

// import
var SocialMediaWechatOriginIcon = require("next/dynamic")["default"](function () {
  return import("@meepshop/images/lib/socialMediaWechatOriginIcon");
}, {
  loadableGenerated: {
    webpack: function webpack() {
      return [require.resolveWeak("@meepshop/images/lib/socialMediaWechatOriginIcon")];
    },
    modules: ["../../../../meepshop/modules/social-media/lib/hooks/useSocialMedias.js -> " + "@meepshop/images/lib/socialMediaWechatOriginIcon"]
  }
});

var SocialMediaWechatCircleFilledIcon = require("next/dynamic")["default"](function () {
  return import("@meepshop/images/lib/socialMediaWechatCircleFilledIcon");
}, {
  loadableGenerated: {
    webpack: function webpack() {
      return [require.resolveWeak("@meepshop/images/lib/socialMediaWechatCircleFilledIcon")];
    },
    modules: ["../../../../meepshop/modules/social-media/lib/hooks/useSocialMedias.js -> " + "@meepshop/images/lib/socialMediaWechatCircleFilledIcon"]
  }
});

var SocialMediaWechatCircleIcon = require("next/dynamic")["default"](function () {
  return import("@meepshop/images/lib/socialMediaWechatCircleIcon");
}, {
  loadableGenerated: {
    webpack: function webpack() {
      return [require.resolveWeak("@meepshop/images/lib/socialMediaWechatCircleIcon")];
    },
    modules: ["../../../../meepshop/modules/social-media/lib/hooks/useSocialMedias.js -> " + "@meepshop/images/lib/socialMediaWechatCircleIcon"]
  }
});

var SocialMediaWechatIcon = require("next/dynamic")["default"](function () {
  return import("@meepshop/images/lib/socialMediaWechatIcon");
}, {
  loadableGenerated: {
    webpack: function webpack() {
      return [require.resolveWeak("@meepshop/images/lib/socialMediaWechatIcon")];
    },
    modules: ["../../../../meepshop/modules/social-media/lib/hooks/useSocialMedias.js -> " + "@meepshop/images/lib/socialMediaWechatIcon"]
  }
});

var SocialMediaTwitterOriginIcon = require("next/dynamic")["default"](function () {
  return import("@meepshop/images/lib/socialMediaTwitterOriginIcon");
}, {
  loadableGenerated: {
    webpack: function webpack() {
      return [require.resolveWeak("@meepshop/images/lib/socialMediaTwitterOriginIcon")];
    },
    modules: ["../../../../meepshop/modules/social-media/lib/hooks/useSocialMedias.js -> " + "@meepshop/images/lib/socialMediaTwitterOriginIcon"]
  }
});

var SocialMediaTwitterCircleFilledIcon = require("next/dynamic")["default"](function () {
  return import("@meepshop/images/lib/socialMediaTwitterCircleFilledIcon");
}, {
  loadableGenerated: {
    webpack: function webpack() {
      return [require.resolveWeak("@meepshop/images/lib/socialMediaTwitterCircleFilledIcon")];
    },
    modules: ["../../../../meepshop/modules/social-media/lib/hooks/useSocialMedias.js -> " + "@meepshop/images/lib/socialMediaTwitterCircleFilledIcon"]
  }
});

var SocialMediaTwitterCircleIcon = require("next/dynamic")["default"](function () {
  return import("@meepshop/images/lib/socialMediaTwitterCircleIcon");
}, {
  loadableGenerated: {
    webpack: function webpack() {
      return [require.resolveWeak("@meepshop/images/lib/socialMediaTwitterCircleIcon")];
    },
    modules: ["../../../../meepshop/modules/social-media/lib/hooks/useSocialMedias.js -> " + "@meepshop/images/lib/socialMediaTwitterCircleIcon"]
  }
});

var SocialMediaTwitterIcon = require("next/dynamic")["default"](function () {
  return import("@meepshop/images/lib/socialMediaTwitterIcon");
}, {
  loadableGenerated: {
    webpack: function webpack() {
      return [require.resolveWeak("@meepshop/images/lib/socialMediaTwitterIcon")];
    },
    modules: ["../../../../meepshop/modules/social-media/lib/hooks/useSocialMedias.js -> " + "@meepshop/images/lib/socialMediaTwitterIcon"]
  }
});

var SocialMediaLineOriginIcon = require("next/dynamic")["default"](function () {
  return import("@meepshop/images/lib/socialMediaLineOriginIcon");
}, {
  loadableGenerated: {
    webpack: function webpack() {
      return [require.resolveWeak("@meepshop/images/lib/socialMediaLineOriginIcon")];
    },
    modules: ["../../../../meepshop/modules/social-media/lib/hooks/useSocialMedias.js -> " + "@meepshop/images/lib/socialMediaLineOriginIcon"]
  }
});

var SocialMediaLineCircleFilledIcon = require("next/dynamic")["default"](function () {
  return import("@meepshop/images/lib/socialMediaLineCircleFilledIcon");
}, {
  loadableGenerated: {
    webpack: function webpack() {
      return [require.resolveWeak("@meepshop/images/lib/socialMediaLineCircleFilledIcon")];
    },
    modules: ["../../../../meepshop/modules/social-media/lib/hooks/useSocialMedias.js -> " + "@meepshop/images/lib/socialMediaLineCircleFilledIcon"]
  }
});

var SocialMediaLineCircleIcon = require("next/dynamic")["default"](function () {
  return import("@meepshop/images/lib/socialMediaLineCircleIcon");
}, {
  loadableGenerated: {
    webpack: function webpack() {
      return [require.resolveWeak("@meepshop/images/lib/socialMediaLineCircleIcon")];
    },
    modules: ["../../../../meepshop/modules/social-media/lib/hooks/useSocialMedias.js -> " + "@meepshop/images/lib/socialMediaLineCircleIcon"]
  }
});

var SocialMediaLineIcon = require("next/dynamic")["default"](function () {
  return import("@meepshop/images/lib/socialMediaLineIcon");
}, {
  loadableGenerated: {
    webpack: function webpack() {
      return [require.resolveWeak("@meepshop/images/lib/socialMediaLineIcon")];
    },
    modules: ["../../../../meepshop/modules/social-media/lib/hooks/useSocialMedias.js -> " + "@meepshop/images/lib/socialMediaLineIcon"]
  }
});

var SocialMediaFacebookOriginIcon = require("next/dynamic")["default"](function () {
  return import("@meepshop/images/lib/socialMediaFacebookOriginIcon");
}, {
  loadableGenerated: {
    webpack: function webpack() {
      return [require.resolveWeak("@meepshop/images/lib/socialMediaFacebookOriginIcon")];
    },
    modules: ["../../../../meepshop/modules/social-media/lib/hooks/useSocialMedias.js -> " + "@meepshop/images/lib/socialMediaFacebookOriginIcon"]
  }
});

var SocialMediaFacebookCircleFilledIcon = require("next/dynamic")["default"](function () {
  return import("@meepshop/images/lib/socialMediaFacebookCircleFilledIcon");
}, {
  loadableGenerated: {
    webpack: function webpack() {
      return [require.resolveWeak("@meepshop/images/lib/socialMediaFacebookCircleFilledIcon")];
    },
    modules: ["../../../../meepshop/modules/social-media/lib/hooks/useSocialMedias.js -> " + "@meepshop/images/lib/socialMediaFacebookCircleFilledIcon"]
  }
});

var SocialMediaFacebookCircleIcon = require("next/dynamic")["default"](function () {
  return import("@meepshop/images/lib/socialMediaFacebookCircleIcon");
}, {
  loadableGenerated: {
    webpack: function webpack() {
      return [require.resolveWeak("@meepshop/images/lib/socialMediaFacebookCircleIcon")];
    },
    modules: ["../../../../meepshop/modules/social-media/lib/hooks/useSocialMedias.js -> " + "@meepshop/images/lib/socialMediaFacebookCircleIcon"]
  }
});

var SocialMediaFacebookIcon = require("next/dynamic")["default"](function () {
  return import("@meepshop/images/lib/socialMediaFacebookIcon");
}, {
  loadableGenerated: {
    webpack: function webpack() {
      return [require.resolveWeak("@meepshop/images/lib/socialMediaFacebookIcon")];
    },
    modules: ["../../../../meepshop/modules/social-media/lib/hooks/useSocialMedias.js -> " + "@meepshop/images/lib/socialMediaFacebookIcon"]
  }
});

// definition
var _default = function _default(socialMediaType, showFacebook, showLine, showTwitter, showWechat) {
  var _useRouter = (0, _link.useRouter)(),
      domain = _useRouter.domain,
      asPath = _useRouter.asPath;

  var url = "https://".concat(domain).concat(asPath);
  return (0, _react.useMemo)(function () {
    return [showFacebook && {
      key: 'facebook',
      url: "//www.facebook.com/share.php?u=".concat(url),
      Icon: {
        NONE: SocialMediaFacebookIcon,
        CIRCLE: SocialMediaFacebookCircleIcon,
        CIRCLE_FILLED: SocialMediaFacebookCircleFilledIcon,
        ORIGIN: SocialMediaFacebookOriginIcon
      }[socialMediaType]
    }, showLine && {
      key: 'line',
      url: "//line.naver.jp/R/msg/text/?".concat(url, "%0D%0A"),
      Icon: {
        NONE: SocialMediaLineIcon,
        CIRCLE: SocialMediaLineCircleIcon,
        CIRCLE_FILLED: SocialMediaLineCircleFilledIcon,
        ORIGIN: SocialMediaLineOriginIcon
      }[socialMediaType]
    }, showWechat && {
      key: 'wechat',
      url: "//api.addthis.com/oexchange/0.8/forward/wechat/offer?url=".concat(url),
      Icon: {
        NONE: SocialMediaWechatIcon,
        CIRCLE: SocialMediaWechatCircleIcon,
        CIRCLE_FILLED: SocialMediaWechatCircleFilledIcon,
        ORIGIN: SocialMediaWechatOriginIcon
      }[socialMediaType]
    }, showTwitter && {
      key: 'x',
      url: "https://twitter.com/intent/tweet?text=".concat(url),
      Icon: {
        NONE: SocialMediaTwitterIcon,
        CIRCLE: SocialMediaTwitterCircleIcon,
        CIRCLE_FILLED: SocialMediaTwitterCircleFilledIcon,
        ORIGIN: SocialMediaTwitterOriginIcon
      }[socialMediaType]
    }].filter(Boolean);
  }, [socialMediaType, showFacebook, showLine, showTwitter, showWechat, url]);
};

exports["default"] = _default;